import { LosseBlogBink, LossePlaatjie, useSearchParams } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'
import Faq from './post-types/Faq'
import Circle from '~/components/elements/Circle'
import { DebounceInput } from 'react-debounce-input'

export default function FaqOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [searchParams, setSearchParams] = useSearchParams()

  function handleSearchInput(event: React.ChangeEvent<HTMLInputElement>) {
    // Debounced search
    const value = event.target.value
    setSearchParams(
      { search: value },
      {
        state: {
          scroll: false
        }
      }
    )
  }

  return (
    <section data-component="FaqOverview" className="section">
      <div className="container sm:pb-20">
        <div className="bg-site-base rounded-[25px] animate-fade-up animate-duration-500 animate-ease-in-out sm:rounded-[50px] py-6 sm:py-10 px-4 xs:px-6 sm:px-10 xl:px-14">
          <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-10 xl:gap-10">
            <div className="sm:pb-5 xl:pb-[40px] col-span-3">
              <div>
                <Breadcrumbs />
              </div>
              <div className="flex flex-col justify-between sm:h-full sm:pl-5 xl:pl-10 pt-6 xl:pt-11">
                <h1 className="title lg:pb-5 !text-white">{fields?.title}</h1>
                <div className="bg-site-secondary max-sm:mt-4 w-fit rounded-[12px] lg:rounded-[25px] pt-3 sm:pt-6 lg:pt-11 px-6 sm:px-6 lg:px-9 pb-4 sm:pb-6 lg:pb-9">
                  <div className="text-site-nav text-lg sm:text-2xl pb-2 sm:pb-5 font-bold">{fields?.subtitle}</div>
                  <div className="relative">
                    <DebounceInput
                      defaultValue={searchParams.get('search')}
                      onChange={(e) => {
                        handleSearchInput(e)
                      }}
                      className="rounded-full text-site-nav w-full py-[10px] px-5"
                      name="search"
                      type="text"
                      placeholder="Zoeken"
                      minLength={2}
                      debounceTimeout={500}
                    />
                    <svg
                      className="absolute top-0 bottom-0 right-5 my-auto"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="01 align center">
                        <path
                          id="Vector"
                          d="M23.9998 22.5867L17.7378 16.3247C19.3644 14.3353 20.1642 11.7968 19.9716 9.23426C19.7791 6.67173 18.609 4.28123 16.7034 2.55722C14.7977 0.833208 12.3024 -0.0924103 9.73342 -0.0281784C7.16447 0.0360534 4.71848 1.08522 2.9014 2.90231C1.08431 4.7194 0.0351378 7.16539 -0.029094 9.73434C-0.0933258 12.3033 0.832292 14.7987 2.5563 16.7043C4.28031 18.6099 6.67081 19.78 9.23334 19.9725C11.7959 20.1651 14.3344 19.3653 16.3238 17.7387L22.5858 24.0007L23.9998 22.5867ZM9.99978 18.0007C8.41753 18.0007 6.87081 17.5315 5.55522 16.6525C4.23963 15.7734 3.21425 14.524 2.60875 13.0622C2.00324 11.6004 1.84482 9.99182 2.1535 8.43997C2.46218 6.88813 3.22411 5.46266 4.34293 4.34384C5.46175 3.22502 6.88721 2.4631 8.43906 2.15441C9.99091 1.84573 11.5994 2.00416 13.0612 2.60966C14.5231 3.21516 15.7725 4.24054 16.6515 5.55614C17.5306 6.87173 17.9998 8.41845 17.9998 10.0007C17.9974 12.1217 17.1538 14.1551 15.654 15.6549C14.1542 17.1547 12.1208 17.9983 9.99978 18.0007Z"
                          fill="#2F6F7D"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end col-span-2 items-center">
              <LossePlaatjie
                maxWidth={320}
                className="max-lg:absolute w-fit max-lg:object-contain max-lg:h-[90%] max-lg:top-0 max-lg:my-auto max-lg:bottom-0 max-lg:right-5 max-lg:-z-10"
                src={fields?.image}
              />
            </div>
          </div>
        </div>
        <div className="relative">
          <LosseBlogBink pageInfo={fields?.posts?.pageInfo as Maybe<WpPageInfo> | undefined}>
            <Overview fields={fields} />
          </LosseBlogBink>
          <Circle color="#F2DFCE" className="w-[309px] z-10 absolute -bottom-[90px] -right-[50px] xl:right-[50px]" />
        </div>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post,
  VeelgesteldeVraag: Faq
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)

  const [searchParams] = useSearchParams()

  return (
    <div className="bg-site-secondary animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out relative z-20 mt-5 lg:mt-10 max-w-screen-lg mx-auto rounded-[25px] sm:rounded-[50px] px-8 sm:px-10 lg:px-24 py-5 sm:py-10">
      <div className="grid grid-cols-1">
        {fields?.posts?.edges?.map((edge, index) => {
          if (!edge || !edge?.node) return null

          const Component = PostTypes[postTypeName] || PostTypes.Faq

          if (searchParams.get('search')) {
            // @ts-ignore
            if (!edge?.node?.recap?.question?.includes(searchParams.get('search'))) return null
          }

          return (
            <div key={edge.node.uri} className="col-span-1">
              {index !== 0 && <div className="bg-white h-[2px] rounded-full w-full" />}
              <Component data={edge?.node} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
